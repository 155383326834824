import * as React from 'react';

class ServicesSection extends React.Component {
    render() {
        return (
            <section class="section service-part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                {/* <p>my <span>services</span></p> */}
                                <h2></h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-4">
                            <div class="service-card">
                                <h3>01</h3>
                                <span class="flaticon-computer"></span>
                                <i class="flaticon-computer"></i>
                                <h4>Web Development</h4>
                                <p>I have experience in both frontend and backend development. I've worked mostly with React, SQL, Elasticsearch, MongoDB, and .NET Web APIs.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-card service-ghape">
                                <h3>02</h3>
                                <span class="flaticon-computer-1"></span>
                                <i class="flaticon-computer-1"></i>
                                <h4>Task Automation</h4>
                                <p>I enjoy simplifying people's repetitive workflows by providing tools to help automate the boring stuff. I have the most experience with Python, Shell, and Perl scripts.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-card">
                                <h3>03</h3>
                                <span class="flaticon-search"></span>
                                <i class="flaticon-search"></i>
                                <h4>Research</h4>
                                <p>As an undergrad, I worked on several research projects for Mississippi State University's Industrial Engineering Department. My most notable research involvement can be found <a target="_lank" href="https://ieeexplore.ieee.org/document/8547520" >here</a> in a paper posted to IEEE.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-card">
                                <h3>04</h3>
                                <span class="flaticon-project-management"></span>
                                <i class="flaticon-project-management"></i>
                                <h4>API Services</h4>
                                <p>I have experience designing, developing, and managing API services capable of caching, data retrieval, and data processing. Most of my experience in this area has been with .NET Web APIs.</p>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-card service-ghape">
                                <img src="images/RF_corgi.png" />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="service-card">
                                <h3>05</h3>
                                <span class="flaticon-plane"></span>
                                <i class="flaticon-plane"></i>
                                <h4>Misc.</h4>
                                <p>I enjoy new challenges and I'm open to pursuing projects that may not align closely with the experience detailed here.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="service-btn">
                                {/* <a class="btn btn-inline" href="service.html">
                                    <i class="far fa-eye"></i>
                                    <span>show more</span>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default ServicesSection;