import * as React from 'react';

class TestimonialsSection extends React.Component {
    render() {
        return (
            <section class="section testi-part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                {/* <p>Words from my previous <span>clients</span></p> */}
                                <h2>Words from my previous clients</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="testi-slider">
                                <div class="testi-card">
                                    <i class="flaticon-favorites"></i>
                                    <div class="testi-quote">
                                        <img src="images/testimonials/testimonial_upwork_1.png" style={{ height: "75%", width: "75%" }} />
                                    </div>
                                    <div class="testi-meta">
                                        <h5>Upwork Client</h5>
                                        <p>View more details on <a href="https://www.upwork.com/freelancers/~013935ce993679499c" target="_blank" >my Upwork profile</a></p>
                                    </div>
                                </div>
                                <br />
                                <div class="testi-card">
                                    <i class="flaticon-favorites"></i>
                                    <div class="testi-quote">
                                        <p>"Excellent Worker! We'd hire David for any job!"</p>
                                    </div>
                                    <div class="testi-meta">
                                        <h5>My Parents</h5>
                                    </div>
                                </div>
                                <br />
                                {/*
                                <div class="testi-card">
                                    <i class="flaticon-favorites"></i>
                                    <div class="testi-quote">
                                        <p>Oustanding design and collaboration. I'm satisfied work with him.</p>
                                    </div>
                                    <div class="testi-meta">
                                        <h5>sane galonhop</h5>
                                        <p>dubai, uae</p>
                                        <a href="#"><img src="images/testimonial/03.jpg" alt="testimonial" /></a>
                                    </div>
                                </div>
                                <div class="testi-card">
                                    <i class="flaticon-favorites"></i>
                                    <div class="testi-quote">
                                        <p>Oustanding design and collaboration. I'm satisfied work with him.</p>
                                    </div>
                                    <div class="testi-meta">
                                        <h5>martin howelling</h5>
                                        <p>toronto, canada</p>
                                        <a href="#"><img src="images/testimonial/04.jpg" alt="testimonial" /></a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div class="row">
                        <div class="col-lg-12">
                            <div class="testi-btn">
                                <a class="btn btn-inline" href="#">
                                    <i class="fas fa-tint"></i>
                                    <span>drop your quote</span>
                                </a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        );
    }
}
export default TestimonialsSection;