import * as React from 'react';

class Skills extends React.Component {
    render() {
        return (
            <section class="section skills-part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <p>my <span>skills</span></p>
                                <h2>Expert in this technical web software</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row skills-parent">
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/html.png" alt="html" />
                                </div>
                                <div class="skills-name">
                                    <p>html</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/css.png" alt="css" />
                                </div>
                                <div class="skills-name">
                                    <p>css</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/javascript.png" alt="javascript" />
                                </div>
                                <div class="skills-name">
                                    <p>javascript</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/bootstrap.png" alt="bootstrap" />
                                </div>
                                <div class="skills-name">
                                    <p>bootstrap</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/jquery.png" alt="jquery" />
                                </div>
                                <div class="skills-name">
                                    <p>jquery</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/sass.png" alt="sass" />
                                </div>
                                <div class="skills-name">
                                    <p>sass</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/wordpress.png" alt="wordpress" />
                                </div>
                                <div class="skills-name">
                                    <p>wordpress</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/react.png" alt="react" />
                                </div>
                                <div class="skills-name">
                                    <p>react</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/github.png" alt="github" />
                                </div>
                                <div class="skills-name">
                                    <p>github</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/vscode.png" alt="vscode" />
                                </div>
                                <div class="skills-name">
                                    <p>vscode</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/photoshop.png" alt="photoshop" />
                                </div>
                                <div class="skills-name">
                                    <p>photoshop</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="skills-card">
                                <div class="skills-img">
                                    <img src="images/skill/illustrator.png" alt="illustrator" />
                                </div>
                                <div class="skills-name">
                                    <p>illustrator</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Skills;