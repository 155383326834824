import * as React from 'react';

class Portfolio extends React.Component {
    render() {
        return (
            <section class="section port-part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <p>my <span>portfolio</span></p>
                                <h2>Take a look some of my featured projects</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="port-slider">
                                <div class="port-card">
                                    <div class="port-img">
                                        <img src="images/portfolio/01.jpg" alt="portfolio" />
                                    </div>
                                    <div class="port-oly">
                                        <div class="port-btn">
                                            <a class="btn btn-inline" href="#">live preview</a>
                                            <a class="btn btn-inline" href="#">view details</a>
                                        </div>
                                        <div class="port-text">
                                            <h6>Corporate website</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="port-card">
                                    <div class="port-img">
                                        <img src="images/portfolio/02.jpg" alt="portfolio" />
                                    </div>
                                    <div class="port-oly">
                                        <div class="port-btn">
                                            <a class="btn btn-inline" href="#">live preview</a>
                                            <a class="btn btn-inline" href="#">view details</a>
                                        </div>
                                        <div class="port-text">
                                            <h6>App landing page</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="port-card">
                                    <div class="port-img">
                                        <img src="images/portfolio/03.jpg" alt="portfolio" />
                                    </div>
                                    <div class="port-oly">
                                        <div class="port-btn">
                                            <a class="btn btn-inline" href="#">live preview</a>
                                            <a class="btn btn-inline" href="#">view details</a>
                                        </div>
                                        <div class="port-text">
                                            <h6>Job marketplace</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="port-card">
                                    <div class="port-img">
                                        <img src="images/portfolio/04.jpg" alt="portfolio" />
                                    </div>
                                    <div class="port-oly">
                                        <div class="port-btn">
                                            <a class="btn btn-inline" href="#">live preview</a>
                                            <a class="btn btn-inline" href="#">view details</a>
                                        </div>
                                        <div class="port-text">
                                            <h6>creative agency</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="port-card">
                                    <div class="port-img">
                                        <img src="images/portfolio/05.jpg" alt="portfolio" />
                                    </div>
                                    <div class="port-oly">
                                        <div class="port-btn">
                                            <a class="btn btn-inline" href="#">live preview</a>
                                            <a class="btn btn-inline" href="#">view details</a>
                                        </div>
                                        <div class="port-text">
                                            <h6>FM radio station</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="port-show-btn">
                                <a class="btn btn-inline" href="portfolio-list.html">
                                    <i class="far fa-eye"></i>
                                    <span>show more</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Portfolio;