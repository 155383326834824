import * as React from 'react';

class BackToTopBtn extends React.Component {
    render() {
        return (
            <a className="back2top-btn" href="#">
                <i className="fas fa-long-arrow-alt-up"></i>
            </a>
        );
    }
}
export default BackToTopBtn;