import * as React from 'react';

class FAQ extends React.Component {
    render() {
        return (
            <section class="section faq-part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <p>faq <span>question</span></p>
                                <h2>Find your question before we work together</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <div class="faq-parent">
                                <div class="faq-child">
                                    <div class="faq-que">
                                        <button>What needs to be done to place your order ?</button>
                                    </div>
                                    <div class="faq-ans">
                                        <p>psd / png / jpg / pdf / any source file or reference link must be provided. If
									nothing else, tell me your needs, likes, or thoughts.</p>
                                    </div>
                                </div>
                                <div class="faq-child">
                                    <div class="faq-que">
                                        <button>I don't have PSD and JPG but I have Idea...</button>
                                    </div>
                                    <div class="faq-ans">
                                        <p>No problem, you will give me your idea and requirements of your website, in this case
									i'll help you to generate idea.</p>
                                    </div>
                                </div>
                                <div class="faq-child">
                                    <div class="faq-que">
                                        <button>What If I provide JPG file instant of PSD file ?</button>
                                    </div>
                                    <div class="faq-ans">
                                        <p>In this case the photos will be used in the website will not have good quality. But I
									can provide you with similar photos with limited cost.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="faq-parent">
                                <div class="faq-child">
                                    <div class="faq-que">
                                        <button>How long will I take to handover your project ?</button>
                                    </div>
                                    <div class="faq-ans">
                                        <p>It depends on the complexity of your project but I've team so, I'm capable to finish
									your project faster than anyone else.</p>
                                    </div>
                                </div>
                                <div class="faq-child">
                                    <div class="faq-que">
                                        <button>If i don't get my project according to my requirement ?
								</button>
                                    </div>
                                    <div class="faq-ans">
                                        <p>Well It never happened. In this case i'll redesign for you if the design doesn't full
									fill your requirement.</p>
                                    </div>
                                </div>
                                <div class="faq-child">
                                    <div class="faq-que">
                                        <button>Do you use any automated Software conversion ?</button>
                                    </div>
                                    <div class="faq-ans">
                                        <p>Never! All codes are hand-typed code with well commented and W3C Validated.</p>
                                    </div>
                                </div>
                                <div class="faq-child">
                                    <div class="faq-que">
                                        <button>Will I get pixel perfect design ?</button>
                                    </div>
                                    <div class="faq-ans">
                                        <p>Yes, You will get a pixel perfect and fresh design.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default FAQ;