import * as React from 'react';

class ExperienceSection extends React.Component {
    render() {
        return (
            <section className="section about-part cate-active">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <div className="resume-card">
                                <span className="flaticon-medal"></span>
                                <h4>May 2018 - Present</h4>
                                <ul>
                                    <li>
                                        <i className="flaticon-deadline"></i>
                                    </li>
                                    <li>
                                        <h5>Babel Street</h5>
                                        <h6>Software Developer</h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="resume-card">
                                <span className="flaticon-medal"></span>
                                <h4>Jan 2018 - April 2018</h4>
                                <ul>
                                    <li>
                                        <i className="flaticon-deadline"></i>
                                    </li>
                                    <li>
                                        <h5>Facebook</h5>
                                        <h6>eCrime Security Team Intern</h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="resume-card">
                                <span className="flaticon-medal"></span>
                                <h4>May 2017 - Aug 2018</h4>
                                <ul>
                                    <li>
                                        <i className="flaticon-deadline"></i>
                                    </li>
                                    <li>
                                        <h5>Mississippi State University</h5>
                                        <h6>Student Researcher</h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-lg-6">
                            <div className="resume-card">
                                <span className="flaticon-medal"></span>
                                <h4>Feb 2019 - Dec 2020</h4>
                                <ul>
                                    <li>
                                        <i className="flaticon-deadline"></i>
                                    </li>
                                    <li>
                                        <h5>Frontend Developer</h5>
                                        <h6>Xpeedstudio</h6>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}
export default ExperienceSection;