import * as React from 'react';

class SocialMediaIcons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customClass: this.props.customClass
        }
    }

    render() {
        return (
            <ul className={this.state.customClass}>
                {/* <li><a className="icon icon-inline" href="#"><i className="fab fa-facebook-f"></i></a></li> */}
                {/* <li><a className="icon icon-inline" href="#"><i className="fab fa-twitter"></i></a></li> */}
                {/* <li><a className="icon icon-inline" href="https://www.linkedin.com/in/david-mentgen-iii-94a184128/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li> */}
                {/* <li><a className="icon icon-inline" href="#"><i className="fab fa-instagram"></i></a></li> */}
                {/* <li><a className="icon icon-inline" href="#"><i className="fab fa-dribbble"></i></a></li> */}
            </ul>
        );
    }
}
export default SocialMediaIcons;