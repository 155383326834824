import * as React from 'react';
import InnerBanner from '../common/InnerBanner';
import ServicesSection from '../common/ServicesSection';

class Services extends React.Component {
    render() {
        return (
            <div>
                <InnerBanner title="services" />
                <ServicesSection />
            </div>
        );
    }
}
export default Services;