import * as React from 'react';

class ContactSection extends React.Component {
    render() {
        return (
            <section className="section contact-part">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <p>contact <span>me</span></p>
                                <h2>I'll respond ASAP!</h2>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row mb-5">
                        <div className="col-lg-6">
                            <div className="contact-address">
                                <div className="address-icon">
                                    <i className="flaticon-telephone" />
                                </div>
                                <div className="address-text">
                                    <h4>UpWork</h4>
                                    <p><a href="https://www.upwork.com/freelancers/~013935ce993679499c" target="_blank">Profile</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-address">
                                <div className="address-icon">
                                    <i className="flaticon-mail" />
                                </div>
                                <div className="address-text">
                                    <h4>email</h4>
                                    <p>Contact@DMentgen.com</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 col-lg-4">
                            <div className="contact-address">
                                <div className="address-icon">
                                    <i className="flaticon-pin" />
                                </div>
                                <div className="address-text">
                                    <h4>location</h4>
                                    <p>1420 East Delpara <span>Narayanganj, Bangladesh</span></p>
                                </div>
                            </div>
                        </div>
                </div> */}
                    <div className="row">
                        <div className="col-md-6 col-lg-7">
                            <form>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Subject" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="file" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <textarea className="form-control" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-btn">
                                            <button className="btn btn-inline" type="submit" disabled>
                                                <i className="fas fa-paper-plane"></i>
                                                <span>send message</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-lg-5">
                            <div className="contact-img">
                                <img src="images/RF_mail.png" alt="contact" />
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}
export default ContactSection;