import * as React from 'react';

class Pricing extends React.Component {
    render() {
        return (
            <section class="section pricing-part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <p>pricing <span>plan</span></p>
                                <h2>Pricing plan will be change by discussion</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-4">
                            <div class="pricing-card">
                                <div class="pricing-money">
                                    <h3>basic</h3>
                                    <h4>$30.00</h4>
                                    <p>per project</p>
                                </div>
                                <ul class="pricing-list">
                                    <li>One landing page</li>
                                    <li>With responsive</li>
                                    <li>modern design</li>
                                    <li>Three rivision</li>
                                    <li>four section</li>
                                </ul>
                                <div class="pricing-btn">
                                    <a class="btn btn-inline" href="#">
                                        <i class="fas fa-paper-plane"></i>
                                        <span>Purchase plan</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="pricing-card pricing-recom">
                                <div class="pricing-money">
                                    <h3>standard</h3>
                                    <h4>$60.00</h4>
                                    <p>per project</p>
                                </div>
                                <ul class="pricing-list">
                                    <li>One landing page</li>
                                    <li>With responsive</li>
                                    <li>modern design</li>
                                    <li>five rivision</li>
                                    <li>six section</li>
                                </ul>
                                <div class="pricing-btn">
                                    <a class="btn btn-inline" href="#">
                                        <i class="fas fa-paper-plane"></i>
                                        <span>Purchase plan</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="pricing-card">
                                <div class="pricing-money">
                                    <h3>premium</h3>
                                    <h4>$99.00</h4>
                                    <p>per project</p>
                                </div>
                                <ul class="pricing-list">
                                    <li>One landing page</li>
                                    <li>With responsive</li>
                                    <li>modern design</li>
                                    <li>eight rivision</li>
                                    <li>nine section</li>
                                </ul>
                                <div class="pricing-btn">
                                    <a class="btn btn-inline" href="#">
                                        <i class="fas fa-paper-plane"></i>
                                        <span>Purchase plan</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Pricing;