import * as React from 'react';

class Counter extends React.Component {
    render() {
        return (
            <section class="counter-part">
                <div class="counter-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4 col-lg-4">
                                <div class="counter-card count-1">
                                    <i class="flaticon-project-management"></i>
                                    <div class="counter-info">
                                        <h3><span class="counter-number">122</span>+</h3>
                                        <p>Project completed</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <div class="counter-card count-2">
                                    <i class="flaticon-handshake"></i>
                                    <div class="counter-info">
                                        <h3><span class="counter-number">108</span>+</h3>
                                        <p>Satisfied Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <div class="counter-card count-3">
                                    <i class="flaticon-rating"></i>
                                    <div class="counter-info">
                                        <h3><span class="counter-number">98</span>%</h3>
                                        <p>Possitive Feedback</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Counter;