import * as React from 'react';
import SocialMediaIcons from './common/SocialMediaIcons';

class Footer extends React.Component {
    render() {
        return (
            <section class="section footer-part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="footer-logo">
                                <a href="#"><img src="images/RF_logo.png" alt="logo" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <SocialMediaIcons customClass="footer-icon" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <ul class="footer-text">
                                <li>
                                    <h6>&copy; Copyright - <span>2020</span></h6>
                                </li>
                                <li>
                                    <h6>All rights reserved by - <a target="_blank"
                                        href="https://themeforest.net/user/mironcoder">mironcoder</a></h6>
                                </li>
                                <li>
                                    <h6>Developed by - David Mentgen</h6>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Footer;