import './App.css';
import * as React from 'react';
import BackToTopBtn from './components/BackToTopBtn';
import NavBar from './components/NavBar';
import Home from './components/home/Home';
import Contact from './components/contact/Contact';
import Services from './components/services/Services';
import Testimonials from './components/testimonials/Testimonials';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Footer from './components/Footer';

class App extends React.Component {
  render() {
    return (
      <Router>
        <NavBar />
        <BackToTopBtn />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Services" component={Services} />
          <Route exact path="/Testimonials" component={Testimonials} />
          <Route exact path="/Contact" component={Contact} />
        </Switch>
        {/* <Footer /> */}
      </Router>
    );
  }
}

export default App;
