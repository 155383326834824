import * as React from 'react';

class Discussion extends React.Component {
    render() {
        return (
            <section class="discuss-part">
                <div class="container">
                    <div class="discuss-oly">
                        <div class="row discuss-bg">
                            <div class="col-md-7 col-lg-8">
                                <div class="discuss-content">
                                    <h3>Let’s discuss your idea</h3>
                                    <p>Have a thought about your future website? I am always here.</p>
                                </div>
                            </div>
                            <div class="col-md-5 col-lg-4">
                                <div class="discuss-btn">
                                    <a class="btn btn-inline" href="contact.html">
                                        <i class="fas fa-paper-plane"></i>
                                        <span>Get in Touch</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Discussion;