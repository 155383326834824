import * as React from 'react';

class ReferenceSection extends React.Component {
    render() {
        return (
            <section class="section about-part cate-active">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="refer-card">
                                <div class="refer-img">
                                    <img src="images/reference/01.jpg" alt="reference" />
                                </div>
                                <div class="refer-content">
                                    <h4>Maccelam Bond</h4>
                                    <p>Sr. Ux / Ui Designer <span>at Creative It Institute</span></p>
                                    <ul class="refer-contact">
                                        <li><a class="icon icon-inline" href="#"><i class="fas fa-phone-alt" /></a></li>
                                        <li><a class="icon icon-inline" href="#"><i class="fas fa-envelope" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="refer-card">
                                <div class="refer-img">
                                    <img src="images/reference/02.jpg" alt="reference" />
                                </div>
                                <div class="refer-content">
                                    <h4>Lady Watsona</h4>
                                    <p>Deputy Manager <span>at Icon Infotech LTD</span></p>
                                    <ul class="refer-contact">
                                        <li><a class="icon icon-inline" href="#"><i class="fas fa-phone-alt"></i></a></li>
                                        <li><a class="icon icon-inline" href="#"><i class="fas fa-envelope"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default ReferenceSection;