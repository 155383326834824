import * as React from 'react';
import ContactSection from '../common/ContactSection';
import AboutSection from '../common/AboutSection';

class Contact extends React.Component {
    render() {
        return (
            <div>
                <ContactSection />
                <AboutSection />
            </div>
        );
    }
}
export default Contact;