import * as React from 'react';

class ResumeSection extends React.Component {
    constructor(props) {
        super(props);

        this.getClassName = this.getClassName.bind(this);
    }

    getClassName(section) {
        if (section === this.props.section) {
            return "cate-btn active";
        }
        return "cate-btn";
    }

    render() {
        return (
            <section id="AboutMe" className="section resume-part">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                {/* <p>Some information about <span>me</span></p> */}
                                <h2>Some basic information about myself</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="resume-cate-btn">
                                {/* <li>
                                    <button
                                        className={this.getClassName("AboutMe")}
                                        onClick={this.props.onChangeSection}
                                        value="AboutMe"
                                    >
                                        <i className="fas fa-user"></i>
                                        <span>about me</span>
                                    </button>
                                </li> */}
                                <li>
                                    <button
                                        className={this.getClassName("Experience")}
                                        onClick={this.props.onChangeSection}
                                        value="Experience"
                                    >
                                        <i className="fas fa-user-tie"></i>
                                        <span>experience</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={this.getClassName("Education")}
                                        onClick={this.props.onChangeSection}
                                        value="Education"
                                    >
                                        <i className="fas fa-user-graduate"></i>
                                        <span>education</span>
                                    </button>
                                </li>
                                {/* <li>
                                    <button
                                        className={this.getClassName("Reference")}
                                        onClick={this.props.onChangeSection}
                                        value="Reference"
                                    >
                                        <i className="fas fa-users"></i>
                                        <span>reference</span>
                                    </button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default ResumeSection;