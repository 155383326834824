import * as React from 'react';
import { Link } from 'react-router-dom';
import SocialMediaIcons from './common/SocialMediaIcons';

class Banner extends React.Component {
    render() {
        return (
            <section className="banner-part">
                <div className="banner-bg">
                    <div className="container">
                        {/* <img className="circle-shape" src="images/assets/circle.png" alt="circle" /> */}
                        {/* <img className="star-shape" src="images/assets/star.png" alt="star" /> */}
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="banner-img">
                                    <img src="images/RF_programmer.png" alt="hero" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="banner-content">
                                    <SocialMediaIcons customclassName="banner-icon" />
                                    <div className="banner-text">
                                        <span>Hello, my name is</span>
                                        <h1>David Mentgen!</h1>
                                        <p>Let's work together to achieve something great!</p>
                                    </div>
                                    <div className="banner-btn duel-btn">
                                        <a className="btn btn-inline" href="#AboutMe">
                                            <i className="fas fa-arrows-alt"></i>
                                            <span>About Me</span>
                                        </a>
                                        <Link className="btn btn-inline" to="/Services">
                                            <i className="fas fa-boxes"></i>
                                            <span>services</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Banner;