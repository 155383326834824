import * as React from 'react';

class AboutSection extends React.Component {
    render() {
        return (
            <section className="section about-part cate-active">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            {/* <div className="about-summery">
                                <h3>text</h3>
                                <p>text</p>
                            </div> */}
                            <div className="about-list">
                                <ul>
                                    <li>
                                        <h6>UpWork:</h6>
                                        <p><a href="https://www.upwork.com/freelancers/~013935ce993679499c" target="_blank">Profile</a></p>
                                    </li>
                                    <li>
                                        <h6>LinkedIn:</h6>
                                        <p><a href="https://www.linkedin.com/in/david-mentgen-iii-94a184128/" target="_blank">Profile</a></p>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <h6>Email:</h6>
                                        <p>Contact@DMentgen.com</p>
                                    </li>
                                    <li>
                                        <h6>Website:</h6>
                                        <p><a href="https://d3isme.com/" target="_blank">https://D3isme.com</a></p>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="about-btn duel-btn">
                                <a className="btn btn-inline" href="images/miron-cv.pdf" download>
                                    <i className="fas fa-download"></i>
                                    <span>Download CV</span>
                                </a>
                                <a className="btn btn-inline" href="contact.html">
                                    <i className="fas fa-headset"></i>
                                    <span>contact me</span>
                                </a>
                            </div> */}
                        </div>
                        {/* <div className="col-lg-5">
                            <div className="about-img">
                                <img src="images/banner/about.png" alt="about" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}
export default AboutSection;