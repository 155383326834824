import * as React from 'react';
import TestimonialsSection from '../common/TestimonialsSection';
import InnerBanner from '../common/InnerBanner';

class Testimonials extends React.Component {
    render() {
        return (
            <div>
                <InnerBanner title="testimonials" />
                <TestimonialsSection />
            </div>
        );
    }
}
export default Testimonials;