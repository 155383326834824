import * as React from 'react';
import BackToTopBtn from '../BackToTopBtn';
import NavBar from '../NavBar';
import Banner from '../Banner';
import ResumeSection from './ResumeSection';
import AboutSection from '../common/AboutSection';
import ExperienceSection from '../common/ExperienceSection';
import EducationSection from '../common/EducationSection';
import ReferenceSection from './ReferenceSection';
import ServicesSection from '../common/ServicesSection';
import ContactSection from '../common/ContactSection';
import Blog from './Blog';
import Coding from './Coding';
import Portfolio from './Portfolio';
import Skills from '../common/Skills';
import Discussion from './Discussion';
import Counter from './Counter';
import Pricing from '../common/Pricing';
import FAQ from '../common/FAQ';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSection: "Experience"
        }
    }

    handleChangeValue = e => {
        e.preventDefault();
        this.setState({ currentSection: e.currentTarget.value });
    }

    render() {
        return (
            <div>
                <Banner />
                <ResumeSection
                    section={this.state.currentSection}
                    onChangeSection={this.handleChangeValue}
                />
                {this.state.currentSection === "Experience" && <ExperienceSection />}
                {this.state.currentSection === "Education" && <EducationSection />}
            </div>
        );
    }
}
export default Home;