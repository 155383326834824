import * as React from 'react';
import { Link } from 'react-router-dom';

class InnerBanner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Title: this.props.title
        };
    }

    render() {
        return (
            <section className="single-banner">
                <div className="single-oly">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-content">
                                    <h1>{this.state.Title}</h1>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{this.state.Title}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default InnerBanner;