import * as React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: this.props.location.pathname
        }
    }

    changeClass = () => {
        const { location } = this.props;
        return location.pathname === '/' ? 'home' : 'other';
    };

    componentDidUpdate() {
        if (this.state.currentPage !== this.props.location.pathname) {
            this.setState({
                currentPage: this.props.location.pathname
            });
        }

        window.scrollTo(0, 0);
    }

    render() {
        return (
            <nav className="navbar-part">
                <div className="container">
                    <div className="navbar-content">
                        <Link className="navbar-logo" to="/"><img src="images/RF_logo.png" alt="logo" style={{ height: "15%", width: "15%" }} /></Link>
                        <button className="navbar-toggle" type="button"><i className="fas fa-bars" /></button>
                        <div className="navbar-slide">
                            <a className="navbar-cross" href="#"><i className="fas fa-times"></i></a>
                            <ul className="navbar-list">
                                <li className={"navbar-item " + (this.state.currentPage === "/" ? "active" : "")}>
                                    <Link className="navbar-link" to="/">Home</Link>
                                </li>
                                <li className={"navbar-item " + (this.state.currentPage === "/Services" ? "active" : "")}>
                                    <Link className="navbar-link" to="/Services">Services</Link>
                                </li>
                                <li className={"navbar-item " + (this.state.currentPage === "/Testimonials" ? "active" : "")}>
                                    <Link className="navbar-link" to="/Testimonials">Testimonials</Link>
                                </li>
                                <li className={"navbar-item " + (this.state.currentPage === "/Contact" ? "active" : "")}>
                                    <Link className="navbar-link" to="/Contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}
export default withRouter(NavBar);