import * as React from 'react';

class EducationSection extends React.Component {
    render() {
        return (
            <section class="section about-part cate-active">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <div class="resume-card">
                                <span class="flaticon-graduation-cap"></span>
                                <h4>Aug 2016 - Present</h4>
                                <ul>
                                    <li>
                                        <i class="flaticon-deadline"></i>
                                    </li>
                                    <li>
                                        <h5>Mississippi State University</h5>
                                        <h6>Bachelor of Science, Computer Science</h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default EducationSection;