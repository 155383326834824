import * as React from 'react';

class Blog extends React.Component {
    render() {
        return (
            <section class="section blog-part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <p>my <span>blogs</span></p>
                                <h2>Some article about my activity &amp; experience</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-lg-4">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img src="images/blog/01.jpg" alt="blog" />
                                    <div class="blog-oly">
                                        <p>html, css</p>
                                    </div>
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            <p>02 feb 2020</p>
                                        </li>
                                        <li>
                                            <i class="flaticon-share"></i>
                                            <p>5 share</p>
                                        </li>
                                    </ul>
                                    <div class="blog-text">
                                        <h4><a href="#">Business contents insurance iure ipsam ratione is a type of
										business...</a></h4>
                                    </div>
                                    <ul class="blog-link">
                                        <li>
                                            <a class="btn btn-inline" href="#">read more</a>
                                        </li>
                                        <li>
                                            <i class="flaticon-chat"></i>
                                            <p>13 comments</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <img src="images/blog/02.jpg" alt="blog" />
                                    <div class="blog-oly">
                                        <p>javascript</p>
                                    </div>
                                </div>
                                <div class="blog-content">
                                    <ul class="blog-meta">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            <p>02 feb 2020</p>
                                        </li>
                                        <li>
                                            <i class="flaticon-share"></i>
                                            <p>5 share</p>
                                        </li>
                                    </ul>
                                    <div class="blog-text">
                                        <h4><a href="#">Business contents insurance iure ipsam ratione is a type of
										business...</a></h4>
                                    </div>
                                    <ul class="blog-link">
                                        <li>
                                            <a class="btn btn-inline" href="#">read more</a>
                                        </li>
                                        <li>
                                            <i class="flaticon-chat"></i>
                                            <p>13 comments</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="blog-suggest">
                                        <div class="suggest-img">
                                            <a href="#"><img src="images/blog-suggest/01.jpg" alt="blog-suggest" /></a>
                                        </div>
                                        <div class="suggest-content">
                                            <div class="suggest-title">
                                                <h6><a href="#">Business contents insurance is a type of business.</a></h6>
                                            </div>
                                            <div class="suggest-date">
                                                <i class="flaticon-calendar"></i>
                                                <p>02 feb 2020</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="blog-suggest">
                                        <div class="suggest-img">
                                            <a href="#"><img src="images/blog-suggest/02.jpg" alt="blog-suggest" /></a>
                                        </div>
                                        <div class="suggest-content">
                                            <div class="suggest-title">
                                                <h6><a href="#">Business contents insurance is a type of business.</a></h6>
                                            </div>
                                            <div class="suggest-date">
                                                <i class="flaticon-calendar"></i>
                                                <p>02 feb 2020</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="blog-suggest">
                                        <div class="suggest-img">
                                            <a href="#"><img src="images/blog-suggest/03.jpg" alt="blog-suggest" /></a>
                                        </div>
                                        <div class="suggest-content">
                                            <div class="suggest-title">
                                                <h6><a href="#">Business contents insurance is a type of business.</a></h6>
                                            </div>
                                            <div class="suggest-date">
                                                <i class="flaticon-calendar"></i>
                                                <p>02 feb 2020</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="blog-btn">
                                <a class="btn btn-inline" href="blog-list.html">
                                    <i class="far fa-eye"></i>
                                    <span>show more</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Blog;