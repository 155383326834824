import * as React from 'react';

class Coding extends React.Component {
    render() {
        return (
            <section class="section coding-part">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <p>coding <span>structure</span></p>
                                <h2>How do I organize of my coding structure</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="coding-content">
                                <h3>Build your SAAS landing page using the <span>intelligent BEM interface</span></h3>
                                <p>Blocks, Elements and Modifiers. A smart HTML/CSS structure that can easely be reused. Layout
							driven by the purpose of modularity.</p>
                            </div>
                            <ul class="coding-list">
                                <li>
                                    <span class="coding-round"></span>
                                    <p>Simple and Smart HTML code structure</p>
                                </li>
                                <li>
                                    <span class="coding-round"></span>
                                    <p>Works reintegrated in any part of the layout</p>
                                </li>
                                <li>
                                    <span class="coding-round"></span>
                                    <p>Reuse the elements from one design to another</p>
                                </li>
                                <li>
                                    <span class="coding-round"></span>
                                    <p>Looks good on all devices Responsive code</p>
                                </li>
                                <li>
                                    <span class="coding-round"></span>
                                    <p>Meaningful comment in each section</p>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <div class="coding-img">
                                <img src="images/banner/coding.jpg" alt="coding" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Coding;